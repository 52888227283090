import React, { useContext } from "react";
import { graphql } from "gatsby";
import UnitDetails from "components/database/unit-details";
import UnitLayout from "components/unit-layout";
import { LocaleContext } from "context/locale-context";

function UnitTemplate({ pageContext, data, location }) {
  const locale = useContext(LocaleContext);
  let isModal = false;
  // We don't want to show the modal if a user navigates
  // directly to a unit so if this code is running on Gatsby's
  // initial render then we don't show the modal, otherwise we
  // do.
  if (
    typeof window !== "undefined" &&
    window.___MNEMONIC_INITIAL_RENDER_COMPLETE
  ) {
    isModal = true;
  }
  if (
    data.unitsJson.id ==
    "fee036ac3f9e03a71934b2694e81c0178d71223efe805122b3a4797146610495"
  ) {
    console.log("hey", data);
  }
  return (
    <UnitLayout location={location} isModal={isModal}>
      <UnitDetails unit={data.unitsJson} locale={locale} />
    </UnitLayout>
  );
}

export default UnitTemplate;

export const pageQuery = graphql`
  query($id: String) {
    unitsJson(jsonId: { eq: $id }) {
      jsonId
      incident_codes
      collections
      description
      filename
      online_link
      online_title_ar
      online_title_en
      summary_ar
      summary_en
      upload_date
      cid_date_of_acquisition
    }
  }
`;
